import styles from "components/sections/mainBanner.module.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from "react-responsive-carousel";
import CarouselLeftButton from "images/icons/carousel_left_button.svg";
import CarouselRightButton from "images/icons/carousel_right_button.svg";
import classNames from "classnames";
import Image from "next/image";
import testBanner from "images/test_banner.png";
import testBannerMobile from "images/test_banner_mobile.png";
import {useEffect, useRef, useState, useContext} from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Autoplay, Navigation, Pagination} from "swiper";
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";
import {Axios} from "api";
import ArrowLeftCircle from "images/icons/arrow_left_circle.svg";
import ArrowRightCircle from "images/icons/arrow_right_circle.svg";
import {captureException} from "@sentry/nextjs";
import Link from "next/link";
import utilStyles from 'styles/utils.module.scss'
import Loading from "components/Loading";
import {eventsRoute} from "common/const";
import {isApp, isIOSChrome, swiperCustomAnimation} from "common/utils";
import ModalContext from "context/ModalContext";
import {useRouter} from "next/router";


SwiperCore.use([Navigation, Pagination, Autoplay]);

const BannerContent = ({banner}) => {
    return (
        <>
            <img style={{width: '100%'}} src={banner.mobile_image_url} alt={banner.text || "배너"} />
            {
                !banner.hide_overlay &&
                <div className={styles.overlay}/>
            }
            <div className={styles.textContainer}>
                <span className={styles.bannerText}>{banner.text}</span>
                <span className={styles.bannerSubText}>{banner.sub_text}</span>
            </div>
            <span className={styles.bannerTitle}>{banner.title}</span>
        </>
    )
}


export default function MainBanner({banners, isLoading, hidePaginator, small}) {
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);

    const prevRef = useRef(null);
    const nextRef = useRef(null);


    const swiperRef = useRef();
    const swipedRef = useRef(0);

    const {openChuseokModal, openCouponModalByIdentifier} = useContext(ModalContext);

    const router = useRouter();


    const onClick = (banner, idx) => {
        console.log('banner', banner);
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'BannerClick', {
                bannerIndex: idx,
                adminTitle: banner.admin_title,
                image_url: banner.image_url,
                link: banner.link
            }
        );

        if (banner.link) {
            router.push(banner.link);
        }
        if (banner?.modal_identifier && !isApp()) {
            openCouponModalByIdentifier(banner.modal_identifier);
        }
        if (banner?.app_modal_identifier && isApp()) {
            openCouponModalByIdentifier(banner.app_modal_identifier);
        }
    };

    const onShowMoreClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('BannerShowMoreClick');
    }

    useEffect(() => {
        if (!swiperRef.current) return;
        const bannerShowMoreButton = document.getElementById('fractionContainer');
        if (!bannerShowMoreButton) return;
        bannerShowMoreButton.addEventListener('click', onShowMoreClick);
        return () => {
            bannerShowMoreButton.removeEventListener('click', onShowMoreClick);
        }
    }, [swiperRef.current]);

    const onTouchEnd = (swiper) => {
        swipedRef.current = Date.now();
    };

    const onSlideChange = (swiper) => {
        if (Date.now() - swipedRef.current < 1000) {
            typeof mixpanel !== 'undefined' && mixpanel.track(
                'MainPageMainBannerSwipe',
                {
                    activeIndex: swiper.activeIndex,
                    nextBannerName: banners[swiper.activeIndex]?.admin_title,
                }
            );
        }
    }

    const timerRef = useRef(null);

    const swiperAutoPlay = () => {
        if (banners && banners.length <= 1) return;
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            if (swiperRef.current?.swiper) {
                swiperRef.current.swiper.slideNext();
            }
            swiperAutoPlay();
        }, 2000);
    }

    const stopAutoPlay = () => {
        if (timerRef.current) clearTimeout(timerRef.current);
    }

    const resumeAutoPlay = () => {
        swiperAutoPlay();
    }

    useEffect(() => {
        swiperAutoPlay();
    }, []);

    useEffect(() => {
        if (!swiperRef.current) return;
        swiperRef.current.addEventListener('mouseenter', stopAutoPlay);
        swiperRef.current.addEventListener('mouseleave', resumeAutoPlay);
        return () => {
            swiperRef.current.removeEventListener('mouseenter', stopAutoPlay);
            swiperRef.current.removeEventListener('mouseleave', resumeAutoPlay);
        }
    }, [swiperRef.current]);

    return (
        <div className={classNames(styles.root, small ? styles.small : undefined)}>
            <div className={styles.swiperWrapper}>
                {
                    banners && banners.length > 0 ?
                        <Swiper
                            className={styles.swiper}
                            ref={swiperRef}
                            loop={banners && (banners.length > 1)}
                            onSetTranslate={swiperCustomAnimation}

                            // autoplay={{
                            //     delay: 3500,
                            //     disableOnInteraction: false,
                            //     pauseOnMouseEnter: true,
                            //     stopOnLastSlide: false,
                            // }}
                            onInit={(swiper) => {
                                swiper.params.navigation.prevEl = prevRef.current;
                                swiper.params.navigation.nextEl = nextRef.current;
                                swiper.navigation.init();
                                swiper.navigation.update();
                            }}
                            onSlideChangeTransitionEnd={(swiper) => {
                                swiperAutoPlay();
                            }}
                            spaceBetween={0}
                            slidesPerView={1}
                            threshold={20}
                            pagination={{
                                type: 'fraction',
                                // renderFraction: (currentClass, totalClass) => {
                                //     return
                                // }
                                // renderBullet: (index, className) => {
                                //     return <span>{index}</span>
                                // }
                                horizontalClass: hidePaginator ? styles.hiddenSwiper : styles.swiperHorizontal,
                                currentClass: hidePaginator ? styles.hiddenSwiper : styles.swiperCurrent,
                                renderFraction: (currentClass, totalClass) => {
                                    return `
                                    <a href="${eventsRoute}" id="fractionContainer">
                                    <div class="${styles.paginationIndicatorWrapper}">
                                        <div class="${styles.paginationIndicator}">
                                            <span class="${currentClass}"></span>/<span class="${totalClass}"></span>
                                        </div>
                                        <div class="${styles.paginationSeeMoreButton}">
                                            <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.9975 2.39062L11.1356 7.5287C11.3959 7.78905 11.3959 8.21116 11.1356 8.47151L5.9975 13.6096L5.05469 12.6668L9.72135 8.0001L5.05469 3.33343L5.9975 2.39062Z" fill="#757575"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    </a>
                                `;
                                }
                                // bulletClass: styles.swiperBullet,
                                // bulletActiveClass: styles.swiperBulletActive,
                            }}
                            onSlideChange={onSlideChange}
                            onTouchEnd={onTouchEnd}
                        >
                            {
                                banners.map((banner, idx) => {
                                    return (
                                        <SwiperSlide key={idx}>
                                            {
                                                banner.link ?
                                                    <Link href={banner.link || ''}>
                                                        <a className={styles.bannerItem} onClick={() => onClick(banner, idx)}>
                                                            <BannerContent banner={banner} />
                                                        </a>
                                                    </Link> :
                                                    <div className={styles.bannerItem} onClick={() => onClick(banner, idx)}>
                                                        <BannerContent banner={banner} />
                                                    </div>
                                            }
                                        </SwiperSlide>
                                    )
                                })
                            }
                            {/*<div className={styles.buttonControllerContainer}>*/}
                            {/*    <div ref={prevRef} className={styles.swiperPrevButton} style={{display: isMobile ? 'none' : undefined}}>*/}
                            {/*        <ArrowLeftCircle viewBox="0 0 24 24" />*/}
                            {/*    </div>*/}
                            {/*    <div ref={nextRef} className={styles.swiperNextButton} style={{display: isMobile ? 'none' : undefined}}>*/}
                            {/*        <ArrowRightCircle viewBox="0 0 24 24" />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </Swiper>
                        :
                        <div className={classNames(styles.root, small ? styles.small : undefined, styles.placeholder)} />
                }
            </div>
        </div>
    )
}
